import "vant/es/dialog/style";
import _Dialog from "vant/es/dialog";
import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.slice.js";
import { trackUserEvent, webfunnyTrack } from 'base/utils/rp'; // import { ImageThemed } from 'components'

import ImageThemed from 'components/ImageThemed.vue';
import xEnum from 'base/utils/enum';
import { mapState } from 'vuex';
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking';
export default {
  name: 'GoodsNormalItem',
  components: {
    ImageThemed: ImageThemed
  },
  props: {
    itemStyle: {
      type: Object,
      default: function _default() {}
    },
    source: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    index: {
      type: Number,
      default: function _default() {
        return 0;
      }
    },
    type: {
      type: Number,
      default: function _default() {
        return 1;
      }
    },
    pageSource: {
      type: Number,
      default: function _default() {
        return xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue();
      }
    },
    showRankTag: {
      type: Boolean,
      default: function _default() {
        return false;
      }
    },
    onClickItem: {
      type: Function,
      default: function _default() {}
    }
  },
  data: function data() {
    return {};
  },
  computed: _objectSpread(_objectSpread({}, mapState({
    pId: function pId(state) {
      return state.appState.pId;
    },
    userId: function userId(state) {
      return state.user.userId;
    },
    phone: function phone(state) {
      return state.user.phone;
    },
    openid: function openid(state) {
      return state.user.openid;
    },
    unionId: function unionId(state) {
      return state.user.unionId;
    },
    webFingerPrint: function webFingerPrint(state) {
      return state.user.webFingerPrint;
    },
    showGoodPrice: function showGoodPrice(state) {
      return state.appState.showGoodPrice;
    }
  })), {}, {
    saleDesc: function saleDesc() {
      return _.replace(this.source.saleDesc, '兑换热度', '兑换热度');
    },
    eventInfo: function eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: this.source.id,
          goodsPrice: this.source.price,
          score: this.source.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (this.source.i + 1) * (this.source.j + 1) - 1,
          goodsName: this.source.goodsName,
          goodsIndex: "".concat(this.source.j),
          goodsIndexVertical: "".concat(this.source.i),
          goodsCategoryId: this.source.categoryId
        }
      };
    },
    limitTitleInNine: function limitTitleInNine() {
      var _this$source$goodsNam, _this$source;

      var title = (_this$source$goodsNam = (_this$source = this.source) === null || _this$source === void 0 ? void 0 : _this$source.goodsName) !== null && _this$source$goodsNam !== void 0 ? _this$source$goodsNam : '';

      if (title.length > 9) {
        return title.slice(0, 9) + '...';
      }

      return title;
    }
  }),
  methods: {
    onClick: function onClick() {
      var source = this.source,
          index = this.index,
          type = this.type;

      if (source.stockNum <= 0) {
        _Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        });

        return;
      }

      var count = Number(sessionStorage.getItem('enterProfileCount'));

      if (count && count < 6) {
        count++;
        sessionStorage.setItem('enterProfileCount', count);
      } else {
        sessionStorage.setItem('enterProfileCount', 1);
      }

      this.$router.push({
        path: "/goodsProfile/".concat(source.id),
        query: {
          type: this.type,
          pageSource: this.pageSource
        }
      });
      this.onClickItem(source, index);
      var eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsPrice: this.source.price,
        score: this.source.score,
        goodsIndex: index,
        goodsGroupId: this.type,
        webUrl: window.location.href
      };
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_CLICK.value,
        eventInfo: eventInfo
      });
      webfunnyTrack({
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_CLICK.value,
        eventType: 'goodsClick',
        eventInfo: eventInfo
      });
      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source === null || source === void 0 ? void 0 : source.score,
          goods_group_type: type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: "".concat(source.j),
          goods_index_vertical: "".concat(source.i),
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '商品瀑布流',
          goods_category_id: source.categoryId
        }
      });
    }
  }
};