import _objectSpread from "/root/workspace/dev-client-a/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.json.stringify.js";

var render = function render() {
  var _vm$source$recommendR, _vm$source$styleTags;

  var _vm = this,
      _c = _vm._self._c;

  return _c("div", {
    directives: [{
      name: "track",
      rawName: "v-track:exposure",
      arg: "exposure"
    }],
    staticClass: "container",
    style: _objectSpread({}, _vm.itemStyle),
    attrs: {
      "track-params": JSON.stringify(_vm.eventInfo)
    },
    on: {
      click: _vm.onClick
    }
  }, [_c("van-image", {
    staticClass: "waterfall-img In_c_Img",
    attrs: {
      src: _vm.source.imgUrl || null
    },
    scopedSlots: _vm._u([{
      key: "loading",
      fn: function fn() {
        return [_c("ImageThemed", {
          attrs: {
            width: 100,
            "image-id": "goodsNormalItem.loading"
          }
        })];
      },
      proxy: true
    }])
  }), _c("img", {
    staticClass: "cornerImg",
    attrs: {
      src: _vm.source.cornerImg,
      alt: ""
    }
  }), _vm.source.stockNum <= 0 ? _c("img", {
    staticClass: "dw_img",
    attrs: {
      src: require("base/assets/img/dw.png")
    }
  }) : _vm._e(), _c("div", {
    staticClass: "goods-info flex flex-column"
  }, [_c("div", {
    staticClass: "name bold van-ellipsis"
  }, [_vm._v(_vm._s(_vm.limitTitleInNine))]), (_vm$source$recommendR = _vm.source.recommendReasonList) !== null && _vm$source$recommendR !== void 0 && _vm$source$recommendR.length ? _c("div", {
    staticClass: "recommend-intro fs12 van-ellipsis"
  }, _vm._l(_vm.source.recommendReasonList, function (item) {
    return _c("span", {
      key: item,
      staticClass: "item px1"
    }, [_vm._v(_vm._s(item))]);
  }), 0) : _vm._e(), (_vm$source$styleTags = _vm.source.styleTags) !== null && _vm$source$styleTags !== void 0 && _vm$source$styleTags.length ? _c("div", {
    staticClass: "goods-tag flex flex-wrap fs11 van-ellipsis"
  }, _vm._l(_vm.source.styleTags, function (item) {
    return _c("span", {
      key: item.text,
      staticClass: "item px1 mr1 mt1 fs11",
      style: _objectSpread({}, item.styleWrap)
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 0) : _vm._e(), _c("div", {
    staticClass: "goods-bottom"
  }, [_c("div", {
    staticClass: "color_primary bold mr2 flex items-end"
  }, [_c("span", {
    staticClass: "point fs16"
  }, [_vm._v(_vm._s(_vm.source.costPoints) + _vm._s(_vm.globalPointName))]), _vm.showGoodPrice ? _c("span", {
    staticClass: "price fs14"
  }, [_vm._v("+" + _vm._s(_vm.source.price) + "元")]) : _vm._e()])]), _c("div", {
    staticClass: "flex fs12 goods-desc justify-between"
  }, [_c("span", {
    staticClass: "sale-desc color_primary"
  }, [_vm._v(_vm._s(_vm.saleDesc))]), _c("span", {
    staticClass: "original-price color_tips"
  }, [_vm._v(_vm._s(_vm.source.originalPrice) + "元")])])])], 1);
};

var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };